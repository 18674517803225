import * as React from "react";
import styled from 'styled-components';
import Helmet from 'react-helmet';
import SiteWrapper from '../siteWrapper';
import { Link } from "gatsby";

// styles
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Content = styled.div`
  color: #fff;
`
const Links = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: auto auto auto;
`
const L = styled(Link)`
  display: inline-block;
  background: #fff;
  color: ${props => props.theme.fg};
  font-family: ${props => props.theme.fjalla};
  border-radius: 10px;
  padding: 20px;
  text-decoration: none;
  text-align: center;
`

// markup
const NotFoundPage = () => {
  return (
    <SiteWrapper>
      <Helmet>
        <title>KjG St. Barbara / Littenweiler - 404</title>
        <meta name="description" content="Diese Seite konnte nicht gefunden werden..." />
        <meta name="keywords" content="KjG, Littenweiler, Freiburg, St. Barbara, Sommerlager, Zeltlager, Jugendfreizeit, Kinderfreizeit" />
      </Helmet>
      <Wrapper>
        <Content>
          <h1>Sorry</h1>
          <h2>Hier hat irgendjemand einen Fehler gemacht. Wahrscheinlich einer von den neuen Leitern...</h2>
          <h3>Check stattdessen das hier aus:</h3>
          <Links>
            <L to="/">Startseite</L>
            <L to="/">Sommerlager</L>
            <L to="/">Gruppenstunden</L>
          </Links>
        </Content>
      </Wrapper>
    </SiteWrapper>
  )
}

export default NotFoundPage;
